<template>
    <div style="min-height: 200px">
        <canvas  id="chart123"  height="80vh"  ref="canvas"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    name: 'IntervalsChart',
    props: ['periods' ,'interval_time', 'workout_time'],
    data() {
        return {
            chart: null,
            config: {},
            goal: [],
            user: {},
            total_act: []
        }
    },
    methods: {

    },
    mounted() {
        this.user = this.$auth.user();

        const fillArray = (value, len) => {
            for (let i = 0; i < len; i++) {
                this.goal.push(value);
            }
            return this.goal;
        }

        this.goal = fillArray(this.user.patient.goal, this.interval_time.length);

        const ctx = this.$refs.canvas;
        console.log( "ON", this.interval_time)
        console.log( "OFF", this.workout_time)

        this.total_act = this.interval_time.map((a, i) => a + this.workout_time[i]);


        this.config = {
            type: 'line',
            data: {
                labels: this.periods,
                datasets: [
                    {
                        label: "Patient's Goal",
                        data: this.goal,
                        backgroundColor: 'red',
                        borderColor: 'red',
                        borderWidth: 1,
                    },
                    {
                        label: "Workouts",
                        data: this.workout_time,
                        backgroundColor: '#6477D8',
                        borderColor: '#3F51B1',
                        borderWidth: 1,
                        tension: 0.3,
                        pointBackgroundColor: "white",
                        fill: "origin",
                    },
                    {
                        label: "Intervals",
                        data: this.interval_time,
                        backgroundColor: '#3F51B1',
                        borderColor: '#6477D8',
                        borderWidth: 1,
                        fill: "origin",
                        tension: 0.3,
                        pointBackgroundColor: "white",
                    },
                    {
                        label: "Total Activities",
                        data: this.total_act,
                        backgroundColor: '#D8DDF5',
                        borderColor: '#D8DDF5',
                        borderWidth: 1,
                        fill: "origin",
                        tension: 0.3,
                        pointBackgroundColor: "white",
                    },
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        max: 24
                    },
                    x: {
                        beginAtZero: true,
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };
        this.chart = new Chart(ctx,  this.config);
        this.$forceUpdate()
    },
}

</script>

