<template>
    <dashboard-layout>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <date-picker-menu
                    :label="$t('scoliotracker.from')"
                    :date_prop="
                        $dayjs().subtract(7, 'day').format('YYYY-MM-DD')
                    "
                    v-model="from"
                    @change="startDate"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <date-picker-menu
                    :label="$t('scoliotracker.to')"
                    v-model="to"
                    @change="endDate"
                />
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <div v-if="$auth.user()">
            <div style="min-height: 300px">
                <intervals-chart
                    v-if="showChart"
                    :interval_time="interval_time"
                    :workout_time="workout_time"
                    :periods="periods"
                />
            </div>
            <!-- <div>
                <div>Всего</div>
                <div
                    v-for="interval in intervals"
                    :key="`total-` + interval.id"
                >
                    {{
                        $date(interval.started_at).format("DD/MM/YYYY H:mm:ss")
                    }}-{{
                        $date(interval.ended_at).format("DD/MM/YYYY H:mm:ss")
                    }}
                </div>
            </div> -->
            <div class="subscript">
                <div class="generalTitle">
                    <div class="generalPoint"></div>
                    <span>Время ношения корсета</span>
                </div>
                <div class="generalTitle">
                    <div class="workoutPoint"></div>
                    <span>Тренировки</span>
                </div>
                <div class="generalTitle">
                    <div class="corsetPoint"></div>
                    <span>Общая активность</span>
                </div>
            </div>
        </div>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../../../layouts/dashboard/Index";
import IntervalsChart from "../../../components/IntervalsChart";

export default {
    name: "dashboard-index",
    // eslint-disable-next-line vue/no-unused-components
    components: { IntervalsChart, DashboardLayout },
    data: function () {
        return {
            intervals: [],
            workouts: [],
            showChart: false,
            from: "",
            to: "",
            menu1: "",
            menu2: "",
            periods: [],
            interval_time: [],
            workout_time: [],
            fill: true,
            padding: 8,
            radius: 10,
            selectedDate: [],
        };
    },
    methods: {
        async init() {
            this.from = this.$dayjs().subtract(6, "day").format("YYYY-MM-DD");
        },
        async fetchAnalytics() {
            this.showChart = false;
            console.log('from', this.from);
            console.log('to', this.to);
            console.log('user', this.$auth.user().id);
            let response = await window.axios.get("/api/analytics/getHours", {
                params: { from: this.from, to: this.to, user_id: this.$auth.user().id, type: "intervals"},
            });
            console.log("Response:::", response)
            for(let i = 0; response.data.carbon.length > i; i++) {
                this.selectedDate.push(response.data.carbon[i].slice(5, 10).replace('-', '.'));
            }
            // console.log("selectedDate", this.selectedDate);
            this.periods = response.data.periods;
            this.interval_time = response.data.on;

            let response_workouts = await window.axios.get("/api/analytics/getHours", {
                params: { from: this.from, to: this.to, user_id: this.$auth.user().id, type: "workouts"},
            });
            console.log("workouts", response_workouts);
            this.workout_time = response_workouts.data.on;
            this.showChart = true;
        },
        startDate() {
            console.log("Start Date")
        },
        endDate() {
            console.log("End Date")
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        from() {
            console.log('from');
            this.fetchAnalytics();
        },
        to() {
            this.fetchAnalytics();
        },
    },
};
</script>

<style scoped>
.stackSheet {
    position: relative;
}
.corset {
    position: absolute;
    top: 0;
    left: 0;
}
.subscript {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.generalPoint {
    background-color: #3F51B1;
    height: 25px;
    width: 30px;
    border-radius: 5px;
    margin-right: 5px;
}
.workoutPoint {
    background-color: #6477D8;
    height: 25px;
    width: 30px;
    border-radius: 5px;
    margin-right: 5px;
}
.corsetPoint {
    background-color: #D8DDF5;
    height: 25px;
    width: 30px;
    border-radius: 5px;
    margin-right: 5px;
}
.generalTitle {
    display: flex;
}
</style>
